import React from "react";
import "./header.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import slider1 from "../../assets/slider1.jpeg";
import slider2 from "../../assets/slider2.png";
import slider3 from "../../assets/slider2.png";

const Header = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="header_container">
      <div className="carousel_container">
        <Carousel
          responsive={responsive}
          autoPlay
          infinite
          autoPlaySpeed={1800}
        >
          <img className="headerCarousel_img" src={slider1} alt="FFF" />
          {/* <img className="headerCarousel_img" src={slider2} alt="FFF" />
          <img className="headerCarousel_img" src={slider3} alt="FFF" /> */}
        </Carousel>
      </div>
      {/* <div className="header-rightSide">
        <img src={slider1} alt="FFF" className="header-rightSide_1"/>
        <img src={slider2} alt="FFF" className="header-rightSide_2"/>
      </div> */}
    </div>
  );
};

export default Header;
