export const CalculateStatictics = (orders) => {
  let unPaidtotal = 0;
  let PaidTotal = 0;

  if (orders.length) {
    orders.map((order) => {
      if (order.marketer_paid) {
        PaidTotal += 1;
      } else {
        unPaidtotal += 1;
      }
    });
  }

  return [
    {
      label: "فواتير تم تحصيلها",
      value: PaidTotal,
     
    },
    {
      label: "فواتير لم يتم تحصيلها",
      value: unPaidtotal,
      
    },
  ];
};
