import React, { useContext, useEffect, useState } from "react";
import NavBar from "../../components/nav-bar/navBar";
import Footer from "../../components/footer/footer";
import "./productDetails.css";
import PrimaryButton from "../../components/button/primaryButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { config } from "../../config";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { connect } from "react-redux";
import KetanColor from "../../assets/KetanColor.png";
import KatefaColor from "../../assets/KatefaColor.png";
import { UpdateImageContext } from "../../context/updateImage";
import Carousel from "react-multi-carousel";
import ImageZoom from "../../components/imageZoom/imageZoom";
import SecondaryButton from "../../components/button/secondaryButton";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const ProductDetails = ({ addToCart, removeFromCart, isInCart }) => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [selectedPhoto, setSelectedPhoto] = useState(false);
  const [blurClass, setBlurClass] = useState("");
  const [cornerDirection, setCornerDirection] = useState("");
  const [matrialType, setMatrialType] = useState("");
  const [colorNumber, setColorNumber] = useState();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);
  const [descriptions, setDescriptions] = useState([]);
  const navigate = useNavigate();
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  } else {
    console.log("No token found");
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState({});

  const handleSelect = (id, title, value, price, marketer_price) => {
    setSelectedDescription((prevSelected) => ({
      ...prevSelected,
      [id]: { title, value, price, marketer_price },
    }));

    if (price > 0 || marketer_price > 0) {
      if (user === null) {
        setProduct({
          ...product,
          price: price,
        });
        setProductPrice(price);
      } else {
        setProduct({
          ...product,
          marketer_price: marketer_price,
        });
      }
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1,
    },
  };

  const handleClick = (photo) => {
    setSelectedPhoto(true);
    setBlurClass("blur-background");
  };

  const handleClose = () => {
    setSelectedPhoto(false);
    document.body.classList.remove("blur-background");
    setBlurClass("");
  };

  async function handleDirectBuy() {
    await addToCart({
      id,
      name: productName,
      price: productPrice,
      imageUrl: "",
      imageType: "",
      cornerDirection: cornerDirection,
      matrialType: matrialType,
      color: colorNumber,
      productLink: `https://abdurhman-supplements.com/#/productDetails/${id}`,
      descriptions: selectedDescription,
    });
    await setUpdateImage(!updateImage);

    navigate("/checkout");
  }

  useEffect(() => {
    axios
      .get(config.baseURL + `Products/getProduct?id=${id}`)
      .then((response) => {
        const groupedDescById = response.data.descriptions.reduce(
          (acc, obj) => {
            // If the id doesn't exist in the accumulator, create an empty array for that id
            if (!acc[obj.desc_id]) {
              acc[obj.desc_id] = [];
            }

            // Push the object into the array for that id
            acc[obj.desc_id].push(obj);

            return acc;
          },
          {}
        );
        setProduct(response.data.product);
        setProductName(response.data.product.name);
        setProductPrice(response.data.product.price);
        setDescriptions(groupedDescById);
      });

    window.scrollTo(0, 0);
  }, [id]);

  console.log(product);
  return (
    <div>
      {selectedPhoto ? (
        <div className="modal">
          <img
            src={`data:${product.image_type};base64,${product.image}`}
            alt="FFF"
            className="modal-photo"
          />
          <button onClick={handleClose} className="close-button">
            Close
          </button>
        </div>
      ) : null}

      {isModalOpen && (
        <div
          id="authentication-modal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed inset-0 z-50 flex justify-center items-center w-full h-screen overflow-y-auto overflow-x-hidden"
        >
          <div className="relative p-4 w-full max-w-md max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                  ضع السعر النهائى للعميل فى الفاتورة
                </h3>
                <button
                  type="button"
                  onClick={() => {
                    toggleModal();
                    setBlurClass("");
                  }}
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5">
                <form className="space-y-4" action="#">
                  <div>
                    <label
                      htmlFor="price"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Price
                    </label>
                    <input
                      type="number"
                      name="price"
                      id="price"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="price"
                      onChange={(e) => {
                        setProductPrice(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    onClick={() => {
                      addToCart({
                        id,
                        name: productName,
                        price: product.marketer_price,
                        finalPrice: productPrice,
                        imageUrl: "",
                        imageType: "",
                        cornerDirection: cornerDirection,
                        matrialType: matrialType,
                        color: colorNumber,
                        descriptions: selectedDescription,
                        productLink: `https://abdurhman-supplements.com/#/productDetails/${id}`,
                      });
                      setUpdateImage(!updateImage);
                      toggleModal();
                      setBlurClass("");
                    }}
                  >
                    Add to cart
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={`productDetails-page ${blurClass}`}>
        <NavBar />
        <div className="body">
          <div className="productDetials-content">
            {product ? (
              <div className="product-content left-column">
                <h3 className="productName">{product.name}</h3>
                <div className="productPrice">
                  {String(
                    user === null ? product.price : product.marketer_price
                  ).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  EGP
                </div>
                {product.company_name !== "بدون شركة" ? (
                  <div dir="rtl" style={{ marginTop: "1rem" }}>
                    شركة : {product.company_name}
                  </div>
                ) : null}

                {Object.entries(descriptions).map(([id, descriptions]) => (
                  <div key={id} style={{ marginTop: "20px" }}>
                    <h3>:{descriptions[0].title}</h3>
                    {descriptions.map((item) => (
                      <button
                        className="desc_button"
                        key={item.value}
                        style={{
                          marginRight: "10px",
                          backgroundColor:
                            selectedDescription[id]?.value === item.value
                              ? "#03045e"
                              : "white",
                          color:
                            selectedDescription[id]?.value === item.value
                              ? "white"
                              : "black",
                        }}
                        onClick={() =>
                          handleSelect(
                            id,
                            item.title,
                            item.value,
                            item.price,
                            item.marketer_price
                          )
                        }
                      >
                        {item.value}
                      </button>
                    ))}
                  </div>
                ))}
                <div className="add-cart">
                  <div className="increase-decrease-container">
                    <button
                      className="increase-decrease-button"
                      style={{ cursor: "pointer" }}
                    >
                      -
                    </button>
                    <button className="increase-decrease-button">1</button>
                    <button
                      className="increase-decrease-button"
                      style={{ cursor: "pointer" }}
                    >
                      +
                    </button>
                  </div>
                  {isInCart ? (
                    <div className="button_container">
                      <PrimaryButton
                        text="حذف من السلة"
                        handleClick={() => removeFromCart(id)}
                      />
                    </div>
                  ) : (
                    <PrimaryButton
                      text="اضافة للسلة"
                      handleClick={() => {
                        if (user === null) {
                          addToCart({
                            id,
                            name: productName,
                            price: productPrice,
                            finalPrice: productPrice,
                            imageUrl: "",
                            imageType: "",
                            cornerDirection: cornerDirection,
                            matrialType: matrialType,
                            color: colorNumber,
                            descriptions: selectedDescription,
                            productLink: `https://abdurhman-supplements.com/#/productDetails/${id}`,
                          });
                          setUpdateImage(!updateImage);
                        } else {
                          setBlurClass("blur-background");
                          toggleModal();
                        }
                      }}
                    />
                  )}
                  <SecondaryButton
                    handleClick={handleDirectBuy}
                    text="شراء مباشر"
                  />
                </div>
              </div>
            ) : null}

            <div className="product-image right-column">
              {product ? (
                <div onClick={handleClick}>
                  <ImageZoom
                    src={`data:${product.image_type};base64,${product.image}`}
                    zoomLevel={2}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
