import React, { useContext, useEffect, useState } from "react";
import Logo from "../../assets/AbdoCoach.png";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Badge from "@material-ui/core/Badge";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Drawer from "@mui/material/Drawer";
import "./navBar.css";
import CartButton from "../button/cartButton";
import axios from "axios";
import { config } from "../../config";
import { connect } from "react-redux";
import { removeFromCart } from "../../redux/cart/actions";
import { fetchProductImages } from "../../redux/cart/actions";
import { CalculateTotal } from "../../utilities/calculateTotal";
import { Link, useNavigate } from "react-router-dom";
import DehazeIcon from "@mui/icons-material/Dehaze";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import HomeIcon from "@mui/icons-material/Home";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import { UpdateImageContext } from "../../context/updateImage";
import PersonIcon from "@mui/icons-material/Person";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const NavBar = ({ cart, removeFromCart, fetchProductImages }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [itemCount, setItemCount] = useState(0);
  const [productName, setProductName] = useState();
  const [category, setCategory] = useState("");
  const [drawerState, setDrawerState] = useState(false);
  const [drawerState2, setDrawerState2] = useState(false);
  const [categories, setCategories] = useState([]);
  const [flag, setFlag] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const [language, setLanguage] = useState(
    i18n.language === "en" ? "en" : "ar"
  );
  const navigate = useNavigate();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);
  const accessToken = Cookies.get("jwt");
  let user = null;

  if (accessToken) {
    try {
      user = jwtDecode(accessToken);
    } catch (error) {
      console.error("Invalid token:", error);
    }
  } else {
    console.log("No token found");
  }

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState(!drawerState);
  };

  const toggleDrawer2 = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerState2(!drawerState2);
  };

  const handleLangugeChange = (event, newAlignment) => {
    setLanguage(newAlignment);
    i18n.changeLanguage(newAlignment);
  };

  function handleSearch() {
    navigate(`/search/${category}/${productName}`);
  }

  function handleSearchMobile(e) {
    e.preventDefault();
    navigate(`/searchMobile/${productName}`);
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer2(anchor, false)}
      onKeyDown={toggleDrawer2(anchor, false)}
    >
      <div className="divider2Content">
        <div
          className="dividerItem_content"
          onClick={() => {
            navigate("/");
          }}
        >
          <div className="divider_icon">
            <HomeIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__home")}</div>
        </div>
        <Divider />
        <div className="dividerItem_content">
          <div className="divider_icon">
            <LocalOfferIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__offers")}</div>
        </div>
        <Divider />
        <div className="dividerItem_content">
          <div className="divider_icon">
            <InfoIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">{t("nav_bar__AboutUs")}</div>
        </div>
        <Divider />
        <div
          className="dividerItem_content"
          onClick={() => {
            navigate("/signIn");
          }}
        >
          <div className="divider_icon">
            <HelpIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">هل انت مسوق ؟</div>
        </div>
        <Divider />

        <div
          className="dividerItem_content"
          onClick={() => {
            if (user) {
              navigate("/myProfile");
            } else {
              navigate("/signIn");
            }
          }}
        >
          <div className="divider_icon">
            <AccountBoxIcon sx={{ fontSize: "2rem" }} />
          </div>
          <div className="divider_text">الملف الشخصى</div>
        </div>
        {/* <label class="language-switcher">
          <input type="checkbox" />
          <span class="slider round"></span>
          <span class="select-fr">FR</span>
          <span class="select-en">EN</span>
        </label> */}
      </div>
    </Box>
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    axios.get(config.baseURL + "Categories/getCategories").then((response) => {
      const categoriesWithImages = response.data.map((category) => ({
        ...category,
        image: `data:${category.image_type};base64,${category.image}`,
      }));

      setCategories(categoriesWithImages);
    });
  }, []);

  useEffect(() => {
    // Update the cart count whenever the cart changes
    setItemCount(cart.length);
  }, [cart]);

  useEffect(() => {
    const productIds = cart.map((item) => item.id);

    if (productIds.length > 0) {
      // Fetch product images when the cart is opened
      fetchProductImages(productIds);
    }
  }, [updateImage]);

  return (
    <div>
      <div className="nav-barMobile_container">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDrawerState(true);
          }}
        >
          <Badge color="secondary" badgeContent={itemCount}>
            <ShoppingCartIcon />{" "}
          </Badge>
        </div>
        <div>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 200,
            }}
            onSubmit={handleSearchMobile}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search products"
              inputProps={{ "aria-label": "search products" }}
              onChange={(e) => setProductName(e.target.value)}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearchMobile}
            >
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
        <div
          onClick={() => {
            setDrawerState2(true);
          }}
        >
          <DehazeIcon />
        </div>
      </div>

      <div
        className="nav-bar_container"
        style={{
          backgroundColor: `${
            scrolling ? "rgba(255, 255, 255, 255)" : "rgba(255, 255, 255, 0.93)"
          }`,
        }}
      >
        <div className="nav-bar_content">
          <div className="logo-container">
            <Link to="/">
              <img alt="FFF" src={Logo} className="logo" />
            </Link>
          </div>
          <div className="nav-bar_items">
            <Link to="/" style={{ textDecoration: "none" }}>
              <div className="nav-bar_item">{t("nav_bar__home")}</div>
            </Link>

            <div className="nav-bar_item">{t("nav_bar__offers")}</div>
            <div className="nav-bar_item">{t("nav_bar__AboutUs")}</div>
            <Link to="/signIn" style={{ textDecoration: "none" }}>
              <div className="nav-bar_item">هل انت مسوق ؟</div>
            </Link>
          </div>

          <div className="search-inputs_container">
            <input
              id="serach_product"
              name="search_product"
              placeholder={t("nav_bar__searchProducts")}
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              className="input"
            />
            <Box sx={{ minWidth: 120 }}>
              <FormControl sx={{ width: "200px" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ fontFamily: "Arabic" }}
                >
                  {t("nav_bar__selectCategory")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={category}
                  label="Age"
                  onChange={handleChange}
                >
                  {categories
                    ? categories.map((category) => (
                        <MenuItem
                          value={category.name}
                          sx={{ fontFamily: "Arabic", fontSize: "20px" }}
                        >
                          {category.name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </Box>
            <button
              className="search-button"
              type="submit"
              onClick={handleSearch}
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDrawerState(true);
            }}
          >
            <Badge color="secondary" badgeContent={itemCount}>
              <ShoppingCartIcon />{" "}
            </Badge>
          </div>

          <div>
            <ToggleButtonGroup
              color="primary"
              value={language}
              exclusive
              onChange={handleLangugeChange}
              aria-label="Platform"
            >
              <ToggleButton value="ar">Ar</ToggleButton>
              <ToggleButton value="en">En</ToggleButton>
            </ToggleButtonGroup>
          </div>

          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (user) {
                navigate("/myProfile");
              } else {
                navigate("/signIn");
              }
            }}
          >
            <PersonIcon />
          </div>
        </div>
      </div>

      <Drawer
        anchor="left"
        open={drawerState}
        onClose={toggleDrawer("left", false)}
        PaperProps={{
          sx: {
            width: "20%",
            "@media screen and (max-width: 767px)": {
              width: "65%", // Styles for smaller screens
            },
          },
        }}
      >
        <div className="drawer-content">
          <h2 className="cart-header">سلة التسوق</h2>

          {cart
            ? cart.map((item) => (
                <div className="product-item_container">
                  <div className="product-item">
                    <img
                      src={`data:${item.imageType};base64,${item.imageUrl}`}
                      alt="Product 1"
                    />
                    <div className="product-details">
                      <h3 className="product-name">{item.name}</h3>
                      {Object.entries(item.descriptions).map(
                        ([id, descriptions]) => (
                          <div style={{ fontWeight: "bold" }}>
                            {descriptions.title}: {descriptions.value}
                          </div>
                        )
                      )}
                      <p className="product-price">
                        السعر :{" "}
                        {String(item.finalPrice).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}{" "}
                        EGP
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => removeFromCart(item.id)}
                  >
                    <ClearIcon />
                  </div>
                </div>
              ))
            : null}
        </div>

        <div className="total-section">
          <div className="total">
            <div className="total-price">
              {String(CalculateTotal(cart)).replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}{" "}
              EGP
            </div>
            <div dir="rtl" className="total-text">
              المجموع :
            </div>
          </div>
          <div>
            <Link to={`/checkout`}>
              <CartButton />
            </Link>
          </div>
        </div>
      </Drawer>

      <Drawer
        anchor="right"
        open={drawerState2}
        onClose={toggleDrawer2("right", false)}
      >
        {list("right")}
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  removeFromCart,
  fetchProductImages,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
