import React, { useState } from "react";
import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { config } from "../../config";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { CalculateStatictics } from "../../utilities/calculateStatictics";
import { CalculateStatictics2 } from "../../utilities/calculateStatictics2";

const Statistics = () => {
  const accessToken = Cookies.get("jwt");
  const user = jwtDecode(accessToken);
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [orders, setOrders] = useState([]);

  const [statistics, setStatistics] = useState([
    {
      label: "فواتير تم تحصيلها",
      value: 0,
    },
    {
      label: "فواتير لم يتم تحصيلها",
      value: 0,
    },
  ]);

  const [statistics2, setStatistics2] = useState([
    {
      label: "دفعات تم تحصيلها",
      value: 0,
    },
    {
      label: "دفعات لم يتم تحصيلها",
      value: 0,
    },
  ]);

  const valueFormatter = (item) => `${item.value} فواتير`;
  const valueFormatter2 = (item) => `${item.value} جنيها`;

  const size = {
    width: 600,
    height: 200,
  };

  const data = {
    data: statistics,
    valueFormatter,
  };
  const data2 = {
    data: statistics2,
    valueFormatter2,
  };

  async function handleGetOrders() {
    const response = await axios.get(config.baseURL + "orders/getOrderByUser", {
      params: {
        id: +user.userId,
        fromDate: dayjs(fromDate)
          .hour(0)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DDTHH:mm:ss"),
        toDate: dayjs(toDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format("YYYY-MM-DDTHH:mm:ss"),
      },
    });
    setStatistics(CalculateStatictics(response.data));
    setStatistics2(CalculateStatictics2(response.data));
    setOrders(response.data);
  }
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            label="From"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
          />
          <DatePicker
            label="To"
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
          />
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            size="large"
            onClick={handleGetOrders}
          >
            Send
          </Button>
        </DemoContainer>
      </LocalizationProvider>
      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value} فواتير`,
              arcLabelMinAngle: 35,
              arcLabelRadius: "60%",
              ...data,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fontWeight: "bold",
            },
          }}
          {...size}
        />
      </div>

      <div
        style={{
          marginTop: "2rem",
        }}
      >
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value} جنيها`,
              arcLabelMinAngle: 35,
              arcLabelRadius: "60%",
              ...data2,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fontWeight: "bold",
            },
          }}
          {...size}
        />
      </div>
    </div>
  );
};

export default Statistics;
