import React, { useContext } from "react";
import "./productCard.css";
import PrimaryButton from "../button/primaryButton";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { addToCart, removeFromCart } from "../../redux/cart/actions";
import { useTranslation } from "react-i18next";
import { UpdateImageContext } from "../../context/updateImage";

const ProductCard = ({
  name,
  price,
  image,
  image_type,
  id,
  addToCart,
  removeFromCart,
  isInCart,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { updateImage, setUpdateImage } = useContext(UpdateImageContext);

  return (
    // <div className="card">
    //   <Link
    //     style={{ textDecoration: "none", color: "black" }}
    //     to={`/productDetails/${id}`}
    //   >
    //     <div className="card_img_container">
    //       <img
    //         src={`data:${image_type};base64,${image}`}
    //         alt="FFF"
    //         className="card_img"
    //       />
    //     </div>

    //     <div className="card_productName">{name}</div>
    //   </Link>

    //   <div className="card_productPrice">
    //     {String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
    //   </div>

    //   <div className="card_productPrice_beforeDiscount">
    //     {String((+price * 10) / 100 + +price).replace(
    //       /\B(?=(\d{3})+(?!\d))/g,
    //       ","
    //     )}{" "}
    //     EGP
    //   </div>
    //   {isInCart ? (
    //     <div className="button_container">
    //       <PrimaryButton
    //         text={t("card_delete_from_cart")}
    //         handleClick={() => removeFromCart(id)}
    //       />
    //     </div>
    //   ) : (
    //     <div className="button_container">
    //       <Link
    //         style={{ textDecoration: "none", color: "black" }}
    //         to={`/productDetails/${id}`}
    //       >
    //         <PrimaryButton
    //           text={t("card_add_to_cart")}
    //           // handleClick={() => {
    //           //   addToCart({
    //           //     id,
    //           //     name,
    //           //     price,
    //           //     imageUrl: "",
    //           //     imageType: "",
    //           //     cornerDirection: "",
    //           //     matrialType: "",
    //           //     color: "",
    //           //   });
    //           //   setUpdateImage(!updateImage);
    //           // }}
    //         />
    //       </Link>
    //     </div>
    //   )}
    // </div>

    <div
      className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mr-3 product-card"
      style={{ margin: "auto" }} // This will help center the card
    >
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={`/productDetails/${id}`}
      >
        <div>
          <img
            className="rounded-t-lg"
            src={`data:${image_type};base64,${image}`}
            alt=""
            style={{ height: "200px", objectFit: "cover", margin: "0 auto" }} // Set the height and handle the image fit
          />
        </div>
      </Link>
      <div className="p-5">
        <div>
          <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
            {name}
          </h5>
        </div>
        <p className="mb-3 text-lg font-bold text-gray-700 dark:text-gray-400">
          {String(price).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} EGP
        </p>

        {isInCart ? (
          <div
            style={{
              backgroundColor: "red",
              cursor: "pointer",
            }}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={() => removeFromCart(id)}
          >
            {t("card_delete_from_cart")}
          </div>
        ) : (
          <a
            href={`/productDetails/${id}`}
            className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            {t("card_add_to_cart")}
            <svg
              className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </a>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  isInCart: state.cart.some((item) => item.id === ownProps.id),
});

const mapDispatchToProps = {
  addToCart,
  removeFromCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
