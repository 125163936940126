import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { config } from "../../config";
import NavBar from "../../components/nav-bar/navBar";
import CircularProgress from "@mui/material/CircularProgress";
import ProductCard from "../../components/productCard/productCard";
import SecondaryButton from "../../components/button/secondaryButton";
import Footer from "../../components/footer/footer";

const Company = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { id: categoryId } = useParams(); // renamed id to categoryId to avoid confusion
  const [id, setId] = useState(categoryId);
  const [products, setProducts] = useState([]);
  const [company, setCompany] = useState();
  const [statistics, setStatistics] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const pageSize = 9;

  const handleShowMore = (page) => {
    setPage(page + 1);
    axios
      .get(
        config.baseURL +
          `Products/getProductsForCompany?id=${id}&page=${
            page + 1
          }&pageSize=${pageSize}`
      )
      .then((response) => {
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });
  };

  function handleCompanyClick(statistic) {
    setCompany();
    setProducts([]);
    setPage(1)
    setId(statistic.company_ID);
    setHasMore(true)

    axios
      .get(
        config.baseURL +
          `Products/getProductsForCompany?id=${statistic.company_ID}&page=${1}&pageSize=${pageSize}`
      )
      .then((response) => {
        console.log(response.data)
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });

    axios
      .get(
        config.baseURL + `Companies/getCompany?id=${statistic.company_ID}`
      )
      .then((response) => {
        setCompany(response.data);
      });
  }

  useEffect(() => {
    axios
      .get(
        config.baseURL +
          `Products/getProductsForCompany?id=${id}&page=${page}&pageSize=${pageSize}`
      )
      .then((response) => {
        setProducts([]);
        setProducts((prevProducst) => [...prevProducst, ...response.data]);
        if (response.data.length < pageSize) {
          setHasMore(false);
        }
      });

    axios
      .get(config.baseURL + `Companies/getCompany?id=${id}`)
      .then((response) => {
        setCompany(response.data);
      });

    axios
      .get(config.baseURL + `Products/getProductsCountForCompany`)
      .then((response) => {
        setStatistics(response.data);
      });
  }, [id]);



  return  <div className="category_page">
  <NavBar />
  <div className="body">
    <div className="category_page_content">
      <div className="categoryPage_left-side">
        <h2 className="category_page__header">
          {company ? (
            i18n.language === "en" ? (
              company.name_en
            ) : (
              company.name
            )
          ) : (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
        </h2>

        {/* {company ? (
          <p className="leftSide_paragraph">
            {t("categoryPage_categoryParagraph")}
          </p>
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )} */}

        <div className="productsCard_container">
          {products.length ? (
            products.map((product) => (
              <ProductCard
                name={product.name}
                price={product.price}
                image={product.image}
                image_type={product.image_type}
                id={product.internal_code}
              />
            ))
          ) : (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
        </div>
        {hasMore && (
          <div className="showMoreButton_container">
            <SecondaryButton
              text="Show More"
              handleClick={() => handleShowMore(page)}
            />
          </div>
        )}
      </div>

      <div className="categoryPage_right-side">
        <h3 className="rightSide_header">الشركات</h3>
        {statistics.length ? (
          statistics.map((statistic) => (
            <div
              className="rightSide_category"
              onClick={() => handleCompanyClick(statistic)}
            >
              <div className="rightSide_category-number">
                {statistic.count}
              </div>
              <div className="rightSide_category-name">
                {i18n.language === "en"
                  ? statistic.name_en
                  : statistic.name}
              </div>
            </div>
          ))
        ) : (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        {/* <div className="rightSide_category">
          <div className="rightSide_category-number">232</div>
          <div className="rightSide_category-name">ركن</div>
        </div>

        <div className="rightSide_category">
          <div className="rightSide_category-number">150</div>
          <div className="rightSide_category-name">كنب</div>
        </div>

        <div className="rightSide_category">
          <div className="rightSide_category-number">50</div>
          <div className="rightSide_category-name">شيزلونج</div>
        </div>

        <div className="rightSide_category">
          <div className="rightSide_category-number">70</div>
          <div className="rightSide_category-name">جزامات</div>
        </div>

        <div className="rightSide_category">
          <div className="rightSide_category-number">85</div>
          <div className="rightSide_category-name">مكتبات</div>
        </div>

        <div className="rightSide_category">
          <div className="rightSide_category-number">120</div>
          <div className="rightSide_category-name">باف</div>
        </div> */}
      </div>
    </div>
  </div>

  <Footer />
</div>;
};

export default Company;
