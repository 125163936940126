export const CalculateStatictics2 = (orders) => {
  let unPaidCommission = 0;
  let PaidCommission = 0;

  if (orders.length) {
    orders.map((order) => {
      if (order.marketer_paid) {
        PaidCommission += order.commission_total;
      } else {
        unPaidCommission += order.commission_total;
      }
    });
  }

  return [
    {
      label: "دفعات تم تحصيلها",
      value: PaidCommission,
      
    },
    {
      label: "دفعات لم يتم تحصيلها",
      value: unPaidCommission,
    
    },
  ];
};
