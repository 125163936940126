import React, { useEffect, useState } from "react";
import "./checkOut.css";
import NavBar from "../../components/nav-bar/navBar";
import Footer from "../../components/footer/footer";
import { connect } from "react-redux";
import { makeid } from "../../utilities/makeId";
import { Governates } from "../../utilities/governate";
import { CalculateTotal } from "../../utilities/calculateTotal";
import PrimaryButton from "../../components/button/primaryButton";
import axios from "axios";
import { config } from "../../config";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/warshtyLogo.png";
import { savePDF } from "../../utilities/createPDF";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import { CalculateCommission } from "../../utilities/calculateCommission";

const CheckOut = ({ cart }) => {
  const navigate = useNavigate();
  const date = new Date();
  const accessToken = Cookies.get("jwt");
  let userId = 11;

  if (accessToken) {
    try {
      userId = +jwtDecode(accessToken).userId;
    } catch (error) {
      console.error("Invalid token:", error);
    }
  }
  const [order, setOrder] = useState({
    internal_code: makeid().toString(),
    first_name: "",
    last_name: "",
    phone: "",
    phone2: "",
    email: "",
    d: date,
    date: date.toISOString(),
    dd: date,
    deliverDate: date.toISOString(),
    governate: "...",
    building_number: "",
    address: "",
    notes: "",
    moderator: 0,
    status: "قيد الانتظار",
    products: cart,
    total: CalculateTotal(cart),
    finalTotal: CalculateTotal(cart),
    paid: 0,
    rest: CalculateTotal(cart),
    add: 0,
    shipping: 0,
    pdf: "",
    pdfLink: "",
    user_id: userId,
    commission_total: CalculateCommission(cart),
  });

  console.log(order);

  async function handleClick(e) {
    e.preventDefault();
    //savePDF()
    if (
      order.first_name &&
      order.last_name &&
      order.phone &&
      order.governate &&
      order.address &&
      order.products.length
    ) {
      //const pdf = await savePDF(order);
      axios
        .post(config.baseURL + "Orders/addOrder", {
          order: order,
          pdf: "",
        })
        .then((res) => {
          if (res.status === 200) {
            navigate("/succeededPage");
          }
        })
        .catch((err) => console.log(err));
    } else {
      console.log("Error");
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(order);
  return (
    <div className="checkOut-page">
      <NavBar />
      <div className="body">
        <div className="checkOut-content">
          <div className="receipt-details">
            <h3 className="receipt-details_text">تفاصيل الفاتورة</h3>
            <div className="checkOutDetails-container">
              <div>الاسم الاول</div>
              <div>الاسم الاخير</div>
            </div>

            <div className="checkOutDetails-container">
              <input
                className="checkOut-input"
                style={{ marginLeft: "2rem" }}
                value={order.first_name}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    first_name: e.target.value,
                  })
                }
              />
              <input
                className="checkOut-input"
                value={order.last_name}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    last_name: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>الهاتف</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.phone}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    phone: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>البريد الالكترونى</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.email}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    email: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>الدولة / المنطقة</div>

            <div style={{ display: "flex" }}>
              <div style={{ color: "#777777", fontWeight: "bold" }}>مصر</div>
            </div>

            <div style={{ marginTop: "2rem" }}>المحافظة</div>

            <div style={{ display: "flex" }}>
              <select
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.governate}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    governate: e.target.value,
                  })
                }
              >
                <option value="...">...</option>
                {/* <option>القاهرة</option>
                  <option>الاسكندرية</option> */}
                {Governates
                  ? Governates.map((gov) => (
                      <option key={gov.id} value={gov.governorate_name_ar}>
                        {gov.governorate_name_ar}
                      </option>
                    ))
                  : null}
              </select>
            </div>

            <div style={{ marginTop: "2rem" }}>رقم الطابق</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.building_number}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    building_number: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>عنوان الشارع / الحى</div>

            <div style={{ display: "flex" }}>
              <input
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.address}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    address: e.target.value,
                  })
                }
              />
            </div>

            <div style={{ marginTop: "2rem" }}>ملاحظات الطلب (اختيارى)</div>

            <div style={{ display: "flex" }}>
              <textarea
                className="checkOut-input"
                style={{ width: "100%" }}
                value={order.notes}
                onChange={(e) =>
                  setOrder({
                    ...order,
                    notes: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="receipt">
            <h3 className="receipt_text">طلبك</h3>

            <div className="receiptDetails_content">
              <div className="receiptDetails_content_container">
                <div className="receiptDetails_Text">المنتج</div>
                <div className="receiptDetails_Text">المجموع</div>
              </div>

              {cart
                ? cart.map((item) => (
                    <div className="receiptDetails_content_container marginTop">
                      <div>
                        <div>{item.name}</div>
                        {Object.entries(item.descriptions).map(
                          ([id, descriptions]) => (
                            <div>
                              {descriptions.title}: {descriptions.value}
                            </div>
                          )
                        )}
                      </div>
                      <div>
                        {String(item.finalPrice).replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        )}
                      </div>
                    </div>
                  ))
                : null}

              <div className="receiptDetails_content_container marginTop">
                <div className="receiptDetails_Text">المجموع</div>
                <div className="receiptDetails_Text">
                  {String(CalculateTotal(cart)).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                </div>
              </div>

              <div className="receiptDetails_content_container marginTop">
                <div className="receiptDetails_Text">الشحن</div>
                <div className="receiptDetails_Text">شحن مجانى</div>
              </div>

              <div
                className="receiptDetails_content_container marginTop"
                style={{ border: "none" }}
              >
                <div className="receiptDetails_Text">المجموع</div>
                <div className="receiptDetails_Text">
                  {String(CalculateTotal(cart)).replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginTop: "2rem" }}>
              <PrimaryButton text="تأكيد الطلب " handleClick={handleClick} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const mapStateToProps = (state) => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(CheckOut);
