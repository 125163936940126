import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";
import { config } from "../../config";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";

const Orders = () => {
  const accessToken = Cookies.get("jwt");
  const user = jwtDecode(accessToken);
  const [orders, setOrders] = useState([]);
  const [fromDate, setFromDate] = useState(dayjs(new Date()));
  const [toDate, setToDate] = useState(dayjs(new Date()));

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "internal_code",
      headerName: "كود الفاتورة",
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "الاسم الاول",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "الاسم الثانى",
      flex: 1,
    },
    {
      field: "date",
      headerName: "التاريخ",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "رقم التليفون",
      flex: 1,
    },
    {
      field: "governate",
      headerName: "المحافظة",
      flex: 1,
    },
    {
      field: "building_number",
      headerName: "رقم المبنى",
      flex: 1,
    },
    {
      field: "address",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "status",
      headerName: "حالة الاوردر",
      flex: 1,
    },
    {
      field: "total",
      headerName: "اجمالى الفاتورة",
      flex: 1,
    },
    {
      field: "commission_total",
      headerName: "اجمالى العمولة",
      flex: 1,
    },
    {
      field: "marketer_paid",
      headerName: "تم تسديد دفعتها",
      flex: 1,
    },
  ];

  async function handleGetOrders() {
    const response = await axios.get(config.baseURL + "orders/getOrderByUser", {
      params: {
        id: +user.userId,
        fromDate: dayjs(fromDate)
          .hour(0)
          .minute(0)
          .second(0)
          .format("YYYY-MM-DDTHH:mm:ss"),
        toDate: dayjs(toDate)
          .hour(23)
          .minute(59)
          .second(59)
          .format("YYYY-MM-DDTHH:mm:ss"),
      },
    });

    setOrders(response.data);
  }

  useEffect(() => {
    axios
      .get(config.baseURL + "orders/getOrderByUser", {
        params: {
          id: +user.userId,
          fromDate: dayjs(fromDate)
            .hour(0)
            .minute(0)
            .second(0)
            .format("YYYY-MM-DDTHH:mm:ss"),
          toDate: dayjs(toDate)
            .hour(23)
            .minute(59)
            .second(59)
            .format("YYYY-MM-DDTHH:mm:ss"),
        },
      })
      .then((response) => {
        setOrders(response.data);
      });
  }, []);

  return (
    <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker", "DatePicker"]}>
          <DatePicker
            label="From"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
          />
          <DatePicker
            label="To"
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
          />
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            size="large"
            onClick={handleGetOrders}
          >
            Send
          </Button>
        </DemoContainer>
      </LocalizationProvider>
      <DataGrid
        rows={orders}
        columns={columns}
        autoHeight={false}
        disableColumnResize={false}
        disableSelectionOnClick
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        pageSizeOptions={[5]}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
          },
          "& .MuiDataGrid-cell": {
            wordWrap: "break-word",
            whiteSpace: "normal",
          },
        }}
      />
    </Box>
  );
};

export default Orders;
